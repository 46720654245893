import Box from '@stackhouseos/box-core'

import StepActions from '@stackhouse/rassegnastampa-core/lib/components/StepActions'
import StepContainer from '@stackhouse/rassegnastampa-core/lib/components/StepContainer'
import FormInput from '@stackhouse/rassegnastampa-core/lib/components/Input/FormInput'
import FormButton from '@stackhouse/rassegnastampa-core/lib/components/Button/FormButton'
import FormSelect from '@stackhouse/rassegnastampa-core/lib/components/Select/FormSelect'
import List from '@stackhouse/rassegnastampa-core/lib/components/ListControl'
import EditableList from '@stackhouse/rassegnastampa-core/lib/components/ListControl/EditableList'
import ListHeader from '@stackhouse/rassegnastampa-core/lib/components/ListControl/ListControlHeader'
import Row from '@stackhouse/rassegnastampa-core/lib/components/Row'
import Spacer from '@stackhouse/rassegnastampa-core/lib/components/Spacer'
import Switch from '@stackhouse/rassegnastampa-core/lib/components/Switch'
import DateRange from '@stackhouse/rassegnastampa-core/lib/components/DateRange'

Box.extendControls({
  Row,
  FormInput,
  FormButton,
  FormSelect,
  StepActions,
  StepContainer,
  EditableList,
  ListHeader,
  List,
  Spacer,
  Switch,
  DateRange
})
