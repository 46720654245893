"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publishersPaginationActions = exports.uploadsPaginationActions = exports.usersPaginationActions = exports.feedPaginationActions = exports.getPaginationActions = exports.RESET_PAGINATION = exports.SET_PAGINATION_PROPS = exports.SET_PAGINATION = exports.SET_PAGE = void 0;

var _toolkit = require("@reduxjs/toolkit");

var SET_PAGE = 'SET_PAGE';
exports.SET_PAGE = SET_PAGE;
var SET_PAGINATION = 'SET_PAGINATION';
exports.SET_PAGINATION = SET_PAGINATION;
var SET_PAGINATION_PROPS = 'SET_PAGINATION_PROPS';
exports.SET_PAGINATION_PROPS = SET_PAGINATION_PROPS;
var RESET_PAGINATION = 'RESET_PAGINATION';
exports.RESET_PAGINATION = RESET_PAGINATION;

var getPaginationActions = function getPaginationActions(reducer) {
  return {
    setPage: (0, _toolkit.createAction)("".concat(reducer, "/").concat(SET_PAGE), prepareAction(reducer)),
    setPagination: (0, _toolkit.createAction)("".concat(reducer, "/").concat(SET_PAGINATION), prepareAction(reducer)),
    setPaginationProps: (0, _toolkit.createAction)("".concat(reducer, "/").concat(SET_PAGINATION_PROPS), prepareAction(reducer)),
    resetPagination: (0, _toolkit.createAction)("".concat(reducer, "/").concat(RESET_PAGINATION), prepareAction(reducer))
  };
};

exports.getPaginationActions = getPaginationActions;
var feedPaginationActions = getPaginationActions('feeds');
exports.feedPaginationActions = feedPaginationActions;
var usersPaginationActions = getPaginationActions('users');
exports.usersPaginationActions = usersPaginationActions;
var uploadsPaginationActions = getPaginationActions('uploads');
exports.uploadsPaginationActions = uploadsPaginationActions;
var publishersPaginationActions = getPaginationActions('publishers');
exports.publishersPaginationActions = publishersPaginationActions;

function prepareAction(reducer) {
  return function (content) {
    return {
      payload: content,
      meta: {
        reducer: reducer
      }
    };
  };
}