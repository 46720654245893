"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCheckbox = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _antd = require("antd");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n\n  &.ant-radio-wrapper {\n    ", "\n  }\n\n  &.ant-radio-wrapper-disabled {\n    span {\n      color: ", ";\n      opacity: 0.8;\n    }\n  }\n\n  .ant-radio-inner {\n    background-color: ", ";\n    border-color: ", " !important;\n  }\n\n  .ant-radio-checked {\n    &.ant-radio-disabled {\n      .ant-radio-inner {\n        background-color: ", ";\n        opacity: 0.8;\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledCheckbox = (0, _styledComponents["default"])(_antd.Radio)(_templateObject(), function (_ref) {
  var transparent = _ref.transparent,
      theme = _ref.theme;
  return transparent && "\n      color: ".concat(theme.colors.white, ";\n    ");
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.white;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.white;
}, function (_ref5) {
  var theme = _ref5.theme;
  return theme.colors.white;
});
exports.StyledCheckbox = StyledCheckbox;