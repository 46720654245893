"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.actions = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toolkit = require("@reduxjs/toolkit");

var _set2 = _interopRequireDefault(require("lodash/set"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var _last2 = _interopRequireDefault(require("lodash/last"));

var _findIndex2 = _interopRequireDefault(require("lodash/findIndex"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var initialState = {};
var slice = (0, _toolkit.createSlice)({
  name: 'stepperState',
  initialState: initialState,
  reducers: {
    initStepper: function initStepper(state, _ref) {
      var payload = _ref.payload;
      (0, _set2["default"])(state, "".concat(payload.name), {
        currentStep: 0,
        history: [0],
        stepsnames: payload.steps.map(function (e) {
          return e.name;
        }),
        totalSteps: payload.steps.filter(function (step) {
          return !step.noCount;
        }).length
      });
    },
    historyAdd: function historyAdd(state, _ref2) {
      var payload = _ref2.payload;
      state[payload.name].history.push(payload.step);
      (0, _set2["default"])(state, payload.name, _objectSpread(_objectSpread({}, state[payload.name]), {}, {
        currentStep: payload.step
      }));
    },
    historyPop: function historyPop(state, _ref3) {
      var payload = _ref3.payload;
      state[payload.name].history.pop();
      (0, _set2["default"])(state, payload.name, _objectSpread(_objectSpread({}, state[payload.name]), {}, {
        currentStep: (0, _last2["default"])((0, _get2["default"])(state, [payload.name, 'history'])) || 0
      }));
    },
    setCurrentStep: function setCurrentStep(state, _ref4) {
      var payload = _ref4.payload;
      var indexStep = (0, _findIndex2["default"])((0, _get2["default"])(state, [payload.name, 'stepsnames']), function (e) {
        return e === payload.stepName;
      });

      if (indexStep > -1) {
        state[payload.name].history.push(indexStep);
        (0, _set2["default"])(state, payload.name, _objectSpread(_objectSpread({}, state[payload.name]), {}, {
          currentStep: indexStep
        }));
      }
    }
  },
  extraReducers: {
    '@box/stepperState/update': function boxStepperStateUpdate(state, action) {
      return (0, _set2["default"])(state, action.payload.id, action.payload.value);
    }
  }
});
var actions = slice.actions;
exports.actions = actions;
var _default = slice;
exports["default"] = _default;