"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getData = exports.getAllRoutes = void 0;

var _reselect = require("reselect");

var _prop2 = _interopRequireDefault(require("lodash/fp/prop"));

var _find2 = _interopRequireDefault(require("lodash/find"));

var selectState = (0, _prop2["default"])('app');
var getAllRoutes = (0, _reselect.createSelector)(selectState, (0, _prop2["default"])('routes'));
exports.getAllRoutes = getAllRoutes;

var getData = function getData(reducer, valuePath, id) {
  return (0, _reselect.createSelector)(function (state) {
    return reducer && (0, _prop2["default"])(reducer)(state);
  }, function (data) {
    var forceArray = Array.isArray(data) ? data : [data];
    return data && (0, _prop2["default"])(valuePath)((0, _find2["default"])(forceArray, {
      _id: id
    }));
  });
};

exports.getData = getData;