"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styled = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height:", "px;\n  \n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Styled = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var size = _ref.size;
  return size;
});

exports.Styled = Styled;