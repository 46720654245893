"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogoWrapper = exports.StyledSidebar = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _lodash = require("lodash");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  img {\n    width: 60px;\n    margin-left: 20px;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  width: ", ";\n  background-color: ", ";\n  padding: 60px 40px;\n  ", "\n  overflow: auto;\n  height: 100vh;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledSidebar = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var sidebarWidth = _ref.sidebarWidth;
  return "".concat(sidebarWidth).concat((0, _lodash.isFinite)(sidebarWidth) ? 'px' : '');
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.primary;
}, function (_ref3) {
  var sidebarHeight = _ref3.sidebarHeight;
  return "height: ".concat(sidebarHeight).concat((0, _lodash.isFinite)(sidebarHeight) ? 'px' : '', ";");
});

exports.StyledSidebar = StyledSidebar;

var LogoWrapper = _styledComponents["default"].div(_templateObject2());

exports.LogoWrapper = LogoWrapper;