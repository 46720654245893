import { all, put, call, spawn, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { actions } from './reducer'
import { message } from 'antd'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { actions as actionsAuth } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import BSON from 'bson'
import { PUBLISHERS } from '../App/routes'

function* load() {
  const collection = yield call([mongo, 'collection'], 'publishers')
  const results = yield call([collection, 'find'], {})
  yield put(actions.setItems({ results }))
}

function* getById({ payload }) {
  const collection = yield call([mongo, 'collection'], 'publishers')
  const result = yield call([collection, 'findOne'], { _id: new BSON.ObjectId(payload.id.toString()) })
  yield put(actions.setEdited(result))
}


function* save({ payload }) {
  const collection = yield call([mongo, 'collection'], 'publishers')
  const { name, email, mapper, status, apikey, hidden } = payload.value

  const data = {
    name,
    email,
    mapper,
    status,
    apikey,
    hidden
  }

  try {
    const result = yield call([collection, 'updateOne'], { _id: new BSON.ObjectId(payload.value._id.toString()) }, { $set: data })
    if (!result.modifiedCount) {
      message.success('Nessun publisher modificato');
    }

    message.success('Publisher modificato');
    yield spawn(navigate, `/${PUBLISHERS}`)

  } catch (error) {
    console.log(error)
  }
}

function* saveNew({ payload }) {
  const { value } = payload
  const { name, email, key, mapper, status = 'disable', apikey = false, hidden } = value

  const data = {
    role: 'publisher',
    name,
    email,
    key,
    mapper,
    status,
    apikey,
    hidden
  }

  try {
    yield app.currentUser.functions.addInvitation(data)
    // yield call([collection, 'insertOne'], data)
    message.success('Publisher aggiunto');
    yield spawn(navigate, `/${PUBLISHERS}`)

  } catch (error) {
    console.log(error)
  }
}

export default function* appSagaPub() {
  yield all([
    yield takeLatest([actionsAuth.setLoginData.toString(), 'LOAD_PUBLISHERS'], withLoading('publisherList', load)),
    yield takeLatest('LOAD_PUBLISHER', withLoading('publisherDetail', getById)),
    yield takeLatest('SAVE_PUBLISHER', withLoading('publisherDetail', save)),
    yield takeLatest('SAVE_PUBLISHER_NEW', withLoading('publisherDetail', saveNew)),
  ])
}
