"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StepContainerWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../utils/theme");

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 40px;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  min-height: 100vh;\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  position: relative;\n  flex: 1;\n  padding: 80px 120px  120px 120px;\n\n  ", "\n\n  .stepper-form {\n    width: 100%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n  }\n\n  .logo {\n    margin-bottom: 60px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StepContainerWrapper = _styledComponents["default"].div(_templateObject(), _theme.media.maxWidth('md')(_templateObject2()));

exports.StepContainerWrapper = StepContainerWrapper;