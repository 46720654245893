"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCheckbox = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _antd = require("antd");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  .ant-checkbox-inner {\n    ", "\n  }\n\n  &.ant-checkbox-wrapper-disabled {\n    span {\n      color: ", ";\n      opacity: 0.8;\n    }\n  }\n\n  .ant-checkbox-checked {\n    &.ant-checkbox-disabled {\n      .ant-checkbox-inner {\n        background-color: ", ";\n        opacity: 0.8;\n      }\n    }\n    .ant-checkbox-inner {\n      background-color: ", ";\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledCheckbox = (0, _styledComponents["default"])(_antd.Checkbox)(_templateObject(), function (_ref) {
  var transparent = _ref.transparent,
      theme = _ref.theme;
  return transparent && "\n      border-color: ".concat(theme.colors.secondary, ";\n      background: none;\n    ");
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.white;
}, function (_ref4) {
  var theme = _ref4.theme;
  return theme.colors.secondary;
});
exports.StyledCheckbox = StyledCheckbox;