"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RightImage = exports.LogoContainer = exports.RegisterAccount = exports.LoginFormWrapper = exports.LeftArea = exports.LoginContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../utils/theme");

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        & {\n            display: none;\n        }\n    "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    background: url(assets/newspapers.jpg);\n    min-height: 100vh;\n    width: 40%;\n    background-size: cover;\n    background-position: 50% 50%;\n    border-top-left-radius: 60px;\n\n    ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        & {\n            left: 0; \n            width: 100%; \n            display: flex; \n            justify-content: center;\n        }\n    "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    position: absolute;\n    top: 80px;\n    left: 120px;\n\n    ", "\n    \n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    margin-top: 40px;\n    ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 1;\n    max-width: 512px;\n    width: 70%;\n    margin: auto;\n    display: flex; \n    flex-direction: column; \n    justify-content: center;\n    h2 { margin-bottom: 40px; }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    flex: 1; \n    display: flex;\n    flex-direction: column;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    min-height: 100vh;\n    display: flex;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var LoginContainer = _styledComponents["default"].div(_templateObject());

exports.LoginContainer = LoginContainer;

var LeftArea = _styledComponents["default"].div(_templateObject2());

exports.LeftArea = LeftArea;

var LoginFormWrapper = _styledComponents["default"].div(_templateObject3());

exports.LoginFormWrapper = LoginFormWrapper;

var RegisterAccount = _styledComponents["default"].div(_templateObject4(), function (_ref) {
  var isLoading = _ref.isLoading;
  return isLoading && "\n        opacity: 0.6;\n        pointer-events: none;\n    ";
});

exports.RegisterAccount = RegisterAccount;

var LogoContainer = _styledComponents["default"].div(_templateObject5(), _theme.media.maxWidth('sm')(_templateObject6()));

exports.LogoContainer = LogoContainer;

var RightImage = _styledComponents["default"].div(_templateObject7(), _theme.media.maxWidth('sm')(_templateObject8()));

exports.RightImage = RightImage;