import { all, put, call, spawn, select, takeLatest } from 'redux-saga/effects'
import { navigate } from '@reach/router'
import { message } from 'antd'
import BSON from 'bson'
import { compact } from 'lodash'
import { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { USERS_MANAGEMENT } from '../App/routes'
import { actions } from './reducer'
import moment from 'moment'

function* load() {
  // TODO aggiungere paginazione
  const collection = yield call([mongo, 'collection'], 'users')
  const results = yield call([collection, 'find'], { role: 'master' })
  yield put(actions.setItems({ results }))
}

function* loadByMaster({ payload }) {
  const temp = yield select(state => state.users.edited)
  const collection = yield call([mongo, 'collection'], 'users', { sort: '-createdAt' })
  const results = yield call([collection, 'find'], { role: 'user', masterId: payload.masterId })
  yield put(actions.setEditedUser({ ...temp, users: results }))
}

function* getUserById({ payload }) {
  const collection = yield call([mongo, 'collection'], 'users')
  const result = yield call([collection, 'findOne'], { _id: new BSON.ObjectId(payload.userId.toString()) })
  yield put(actions.setEditedUser(result))
}

// update USER master
function* saveUser({ payload }) {
  const collection = yield call([mongo, 'collection'], 'users')
  const { billing, keywords, publishers, status, email, phone, name, keywordsMax, repeatInterval, subscriptionInterval, subscription, subscribersMax, urlLogo, enableWhatsapp = false } = payload.value
  const { start, end } = subscription || {}

  const data = {
    email,
    phone,
    name,
    urlLogo,
    billing,
    keywords: compact(keywords),
    publishers: compact(publishers),
    status,
    repeatInterval,
    enableWhatsapp,
    keywordsMax: parseInt(keywordsMax),
    subscribersMax: parseInt(subscribersMax),
    subscriptionInterval,
    'subscription.start': Number(start),
    'subscription.end': Number(end),
  }

  try {
    const result = yield call([collection, 'updateOne'], { _id: new BSON.ObjectId(payload.value._id.toString()) }, { $set: data })
    if (!result.modifiedCount) {
      alert('Errore, riprova')
    }
    message.success('Il master è stato modificato');
    yield spawn(navigate, `/${USERS_MANAGEMENT}`)

  } catch (error) {
    console.log(error)
    message.success(`Il master non è stato modificato: ${error}`);
  }
  // yield put(actions.setEditedUser(result))
}

// create NEW USER master
function* saveNew({ payload }) {
  const { billing, keywords, publishers, email, phone, name, keywordsMax, subscription, subscriptionInterval, subscribersMax, urlLogo, enableWhatsapp = false } = payload.value

  const data = {
    role: 'master',
    email,
    phone,
    name,
    urlLogo,
    billing,
    enableWhatsapp,
    keywords: compact(keywords),
    publishers: compact(publishers),
    subscription,
    subscriptionInterval,
    keywordsMax: parseInt(keywordsMax),
    subscribersMax: parseInt(subscribersMax),
  }

  try {
    yield app.currentUser.functions.addInvitation(data)
    message.success('Master aggiunto');
    yield spawn(navigate, `/${USERS_MANAGEMENT}`)

  } catch (error) {
    console.log(error)
  }
}


export default function* appSaga() {
  yield all([
    yield takeLatest('LOAD_USERS', withLoading('usersList', load)),
    yield takeLatest('LOAD_USERS_MASTER', withLoading('subscribersList', loadByMaster)),
    yield takeLatest('LOAD_USER', withLoading('userDetail', getUserById)),
    yield takeLatest('SAVE_USER', withLoading('userDetail', saveUser)),
    yield takeLatest('SAVE_NEW_USER', withLoading('userDetail', saveNew)),
  ])
}
