"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withPaginationReducer = withPaginationReducer;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _actions = require("./actions");

var _lodash = require("lodash");

function _createForOfIteratorHelper(o, allowArrayLike) { var it; if (typeof Symbol === "undefined" || o[Symbol.iterator] == null) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = o[Symbol.iterator](); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var initialState = {
  paginationData: {
    lastPage: null,
    pagination: {
      pageSizeOptions: [12, 24, 48, 54],
      pageSize: 12,
      current: 1,
      total: 0
    }
  }
};

function withPaginationReducer(wrappedReducer, wrappedReducerName, wrappedReducerInitState) {
  return function getHigherOrderReducer() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _objectSpread(_objectSpread({}, initialState), wrappedReducerInitState);
    var action = arguments.length > 1 ? arguments[1] : undefined;

    if ((0, _lodash.get)(action, 'meta.reducer') !== wrappedReducerName) {
      return wrappedReducer(state, action);
    }

    switch (action.type) {
      case "".concat(wrappedReducerName, "/").concat(_actions.SET_PAGE):
        return _objectSpread(_objectSpread({}, state), {}, {
          paginationData: {
            lastPage: null,
            pagination: _objectSpread(_objectSpread({}, state.paginationData.pagination), {}, {
              current: Math.max(1, action.payload)
            })
          }
        });

      case "".concat(wrappedReducerName, "/").concat(_actions.SET_PAGINATION):
        return _objectSpread(_objectSpread({}, state), {}, {
          paginationData: _objectSpread(_objectSpread({}, state.paginationData), {}, {
            pagination: action.payload
          })
        });

      case "".concat(wrappedReducerName, "/").concat(_actions.SET_PAGINATION_PROPS):
        {
          var props = (0, _lodash.toPairs)(action.payload);
          var newPaginationData = (0, _lodash.cloneDeep)(state.paginationData);

          var _iterator = _createForOfIteratorHelper(props),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var _step$value = (0, _slicedToArray2["default"])(_step.value, 2),
                  key = _step$value[0],
                  value = _step$value[1];

              (0, _lodash.set)(newPaginationData, key, value);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }

          return _objectSpread(_objectSpread({}, state), {}, {
            paginationData: newPaginationData
          });
        }

      case "".concat(wrappedReducerName, "/").concat(_actions.RESET_PAGINATION):
        {
          return _objectSpread({}, initialState);
        }

      default:
        return wrappedReducer(state, action);
    }
  };
}