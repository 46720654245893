"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogoFullContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  align-items: center;\n\n  img {\n    height: 75px;\n    margin-left: 0px;\n  }\n\n  h1 {\n    font-size: 36px;\n    font-family: 'serif';\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var LogoFullContainer = _styledComponents["default"].div(_templateObject());

exports.LogoFullContainer = LogoFullContainer;