"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RangeStyled = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    .ant-picker {\n        width: 100%;\n        border-radius: 20px;\n        min-height: 40px;\n        padding-left: 16px;\n    }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var RangeStyled = _styledComponents["default"].div(_templateObject());

exports.RangeStyled = RangeStyled;