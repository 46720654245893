import { createSlice } from '@reduxjs/toolkit'
import { set } from 'lodash'
import { withPaginationReducer } from '@stackhouse/rassegnastampa-core/lib/containers/Pagination/withReducer'

export const initialState = {
  items: [],
  loading: false
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setEditedUser: (state, action) => {
      state.edited = action.payload
    },
    setItems: (state, { payload }) => {
      state.items = payload.results
    },
    clearEdited: (state) => {
      state.edited = undefined
    }
  },
  extraReducers: {
    '@box/users/update': (state, action) => set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
slice.reducer = withPaginationReducer(slice.reducer, slice.name, initialState)
export default slice
