"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BurgerMenu = exports.PageContent = exports.PageContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../utils/theme");

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  font-size: 30px;\n  top: 10px;\n  right: 30px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    & {\n      padding: 60px 30px;\n    }\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  border-top-left-radius: 60px;\n  background-color: ", ";\n  flex: 1;\n  padding: 60px;\n  overflow: auto;\n  height: 100vh;\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  min-height: 100vh;\n  background-color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var PageContainer = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.primary;
});

exports.PageContainer = PageContainer;

var PageContent = _styledComponents["default"].div(_templateObject2(), function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
}, _theme.media.maxWidth('md')(_templateObject3()));

exports.PageContent = PageContent;

var BurgerMenu = _styledComponents["default"].div(_templateObject4());

exports.BurgerMenu = BurgerMenu;