import { all, put, takeLatest } from 'redux-saga/effects'
import { actions } from './reducer'

function* load({ payload }) {
  yield put(actions.setRoutes(payload))
  yield put({ type: 'LOAD_PUBLISHERS' })
}

export default function* appSaga() {
  yield all([
    yield takeLatest('INIT_APP', load),
  ])
}
