import { combineReducers } from '@reduxjs/toolkit'
import auth from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'
import loading from '@stackhouse/rassegnastampa-core/lib/containers/Loading/reducer'
import stepperState from '@stackhouse/rassegnastampa-core/lib/containers/StepperState/reducer'

const context = require.context('../', true, /containers\/[\s\S]*?\/reducer.js$/)
const reducers = context.keys().map(filename => context(filename).default)

export default combineReducers({
  auth: auth.reducer,
  loading: loading.reducer,
  stepperState: stepperState.reducer,
  ...reducers.reduce((acc, inc) => ({ ...acc, [inc.name]: inc.reducer }), {}),
})
