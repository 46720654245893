"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.actions = void 0;

var _toolkit = require("@reduxjs/toolkit");

var _api = _interopRequireDefault(require("../../utils/api"));

var _unset2 = _interopRequireDefault(require("lodash/unset"));

var _set2 = _interopRequireDefault(require("lodash/set"));

var _merge2 = _interopRequireDefault(require("lodash/merge"));

var initialState = {
  isLoggedIn: false,
  role: null,
  publishers: [],
  keywords: [],
  subscription: null,
  plan: null
};
var slice = (0, _toolkit.createSlice)({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setError: function setError(state, _ref) {
      var payload = _ref.payload;
      state.error = payload.message;
    },
    clearError: function clearError(state) {
      (0, _unset2["default"])(state, 'error');
    },
    setRole: function setRole(state, action) {
      state.role = action.payload;
    },
    setLoginData: function setLoginData(state, _ref2) {
      var payload = _ref2.payload;
      (0, _merge2["default"])(state, payload);
    },
    setKeywords: function setKeywords(state, _ref3) {
      var payload = _ref3.payload;
      state.keywords = payload.keywords;
    },
    setPublishers: function setPublishers(state, _ref4) {
      var payload = _ref4.payload;
      state.publishers = payload.publishers;
    },
    resetLoginData: function resetLoginData() {
      return initialState;
    }
  },
  extraReducers: {
    '@box/auth/update': function boxAuthUpdate(state, action) {
      return (0, _set2["default"])(state, action.payload.id, action.payload.value);
    }
  }
});
var actions = slice.actions;
exports.actions = actions;
var _default = slice;
exports["default"] = _default;