import React, { Suspense } from 'react'
import { Router } from '@reach/router'

import GlobalStyle from '@stackhouse/rassegnastampa-core/lib/utils/GlobalStyle'
import Loading from '@stackhouse/rassegnastampa-core/lib/components/Loading'
import PrivateRoute from '@stackhouse/rassegnastampa-core/lib/components/PrivateRoute'
import Dashboard from './pages/Dashboard'
import DefaultPages from '@stackhouse/rassegnastampa-core/lib/pages'

import './utils/initBox'

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        {DefaultPages}
        <PrivateRoute as={Dashboard} path={`/*`} />
      </Router>
      <GlobalStyle />
    </Suspense>
  )
}

export default App
