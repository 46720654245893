"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ForgotPassword = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  > * {\n    margin-left: auto;\n  }\n  margin-bottom: 40px;\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ForgotPassword = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var isLoading = _ref.isLoading;
  return isLoading && "\n    opacity: 0.6;\n    pointer-events: none;  \n  ";
});

exports.ForgotPassword = ForgotPassword;