"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.actions = exports.initialState = void 0;

var _toolkit = require("@reduxjs/toolkit");

var initialState = {
  feeds: true
};
exports.initialState = initialState;
var slice = (0, _toolkit.createSlice)({
  name: 'loading',
  initialState: initialState,
  reducers: {
    setLoading: function setLoading(state, _ref) {
      var payload = _ref.payload;
      state[payload.key] = payload.loading;
    }
  }
});
var actions = slice.actions;
exports.actions = actions;
var _default = slice;
exports["default"] = _default;