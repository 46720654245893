"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isLoading = void 0;

var _reselect = require("reselect");

var _lodash = require("lodash");

var _prop2 = _interopRequireDefault(require("lodash/fp/prop"));

var selectState = (0, _prop2["default"])('loading');
var getLoadingStatus = (0, _reselect.createSelector)(selectState, function (_, key) {
  return key;
}, function (loadingState, key) {
  return loadingState[key];
});

var isLoading = function isLoading(loadingKey) {
  return (0, _reselect.createSelector)(function (state) {
    return getLoadingStatus(state, loadingKey);
  }, _lodash.identity);
};

exports.isLoading = isLoading;