import React from 'react';
import ReactDOM from 'react-dom'

import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { theme } from '@stackhouse/rassegnastampa-core/lib/utils/theme'
import Loading from '@stackhouse/rassegnastampa-core/lib/components/Loading'

import 'moment'
import 'moment/locale/it'
import 'antd/dist/antd.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import configureStore from './redux/store'

const initialState = {}
const { store, persistor } = configureStore(initialState)

console.log('>>>>>>env---->>', process.env.REACT_APP_ENV)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={<Loading />} persistor={persistor}>
          <App />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
