"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPublisherName = exports.getLogoMaster = exports.getUserMasterId = exports.getUserPlan = exports.getUserKeywordsMax = exports.getUserSubscribersMax = exports.getUserSubscription = exports.getRepeatInterval = exports.getNewsletter = exports.getUsersCreated = exports.getUserPrice = exports.getUserEmail = exports.getUserPublishers = exports.getUserKey = exports.getUserStatus = exports.getUserId = exports.getAccountId = exports.getUserZones = exports.getUserKeywords = exports.getUserRole = exports.isUserLogged = exports.getError = void 0;

var _reselect = require("reselect");

var _map = _interopRequireDefault(require("lodash/map"));

var _sortBy = _interopRequireDefault(require("lodash/sortBy"));

var _prop2 = _interopRequireDefault(require("lodash/fp/prop"));

var _keyBy2 = _interopRequireDefault(require("lodash/fp/keyBy"));

var _get2 = _interopRequireDefault(require("lodash/get"));

var selectLoginState = (0, _prop2["default"])('auth');
var getError = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('error'));
exports.getError = getError;
var isUserLogged = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('isLoggedIn'));
exports.isUserLogged = isUserLogged;
var getUserRole = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('role'));
exports.getUserRole = getUserRole;
var getUserKeywords = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('keywords'));
exports.getUserKeywords = getUserKeywords;
var getUserZones = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('zones'));
exports.getUserZones = getUserZones;
var getAccountId = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('accountId'));
exports.getAccountId = getAccountId;
var getUserId = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('userId'));
exports.getUserId = getUserId;
var getUserStatus = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('status'));
exports.getUserStatus = getUserStatus;
var getUserKey = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('key'));
exports.getUserKey = getUserKey;
var getUserPublishers = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('publishers'));
exports.getUserPublishers = getUserPublishers;
var getUserEmail = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('email'));
exports.getUserEmail = getUserEmail;
var getUserPrice = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('price'));
exports.getUserPrice = getUserPrice;
var getUsersCreated = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('createdAt'));
exports.getUsersCreated = getUsersCreated;
var getNewsletter = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('newsletter'));
exports.getNewsletter = getNewsletter;
var getRepeatInterval = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('repeatInterval'));
exports.getRepeatInterval = getRepeatInterval;
var getUserSubscription = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('subscription'));
exports.getUserSubscription = getUserSubscription;
var getUserSubscribersMax = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('subscribersMax'));
exports.getUserSubscribersMax = getUserSubscribersMax;
var getUserKeywordsMax = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('keywordsMax'));
exports.getUserKeywordsMax = getUserKeywordsMax;
var getUserPlan = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('plan'));
exports.getUserPlan = getUserPlan;
var getUserMasterId = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('masterId'));
exports.getUserMasterId = getUserMasterId;
var getLogoMaster = (0, _reselect.createSelector)(selectLoginState, (0, _prop2["default"])('urlLogo'));
exports.getLogoMaster = getLogoMaster;

var getPublisherName = function getPublisherName(getPublishersByKey) {
  return (0, _reselect.createSelector)(getUserPublishers, getPublishersByKey, function (publishers, publishersDict) {
    return (0, _map["default"])(publishers, function (item) {
      return {
        id: item,
        name: publishersDict[item] && publishersDict[item].name || '-'
      };
    });
  });
};

exports.getPublisherName = getPublisherName;