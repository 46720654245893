"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.roles = exports.ORDER_CANCEL = exports.ORDER_SUCCESS = exports.ORDER_PENDING = exports.ACCOUNT = exports.CHANGEPASSWORD = exports.STATUS_VALID = exports.FORBIDDEN = exports.DEACTIVED = exports.RECOVERYPASSWORDCONFIRM = exports.RECOVERYPASSWORD = exports.LOGOUT = exports.LOGIN = exports.CONFIRM = exports.REGISTRATION = exports.DASHBOARD = void 0;
var DASHBOARD = '/';
exports.DASHBOARD = DASHBOARD;
var REGISTRATION = '/registration';
exports.REGISTRATION = REGISTRATION;
var CONFIRM = '/confirm';
exports.CONFIRM = CONFIRM;
var LOGIN = '/login';
exports.LOGIN = LOGIN;
var LOGOUT = '/logout';
exports.LOGOUT = LOGOUT;
var RECOVERYPASSWORD = '/recovery-password';
exports.RECOVERYPASSWORD = RECOVERYPASSWORD;
var RECOVERYPASSWORDCONFIRM = '/password-confirm';
exports.RECOVERYPASSWORDCONFIRM = RECOVERYPASSWORDCONFIRM;
var DEACTIVED = '/402';
exports.DEACTIVED = DEACTIVED;
var FORBIDDEN = '/403';
exports.FORBIDDEN = FORBIDDEN;
var STATUS_VALID = 'active';
exports.STATUS_VALID = STATUS_VALID;
var CHANGEPASSWORD = '/change-password';
exports.CHANGEPASSWORD = CHANGEPASSWORD;
var ACCOUNT = '/account';
exports.ACCOUNT = ACCOUNT;
var ORDER_PENDING = '/order-pending';
exports.ORDER_PENDING = ORDER_PENDING;
var ORDER_SUCCESS = '/order-success';
exports.ORDER_SUCCESS = ORDER_SUCCESS;
var ORDER_CANCEL = '/order-cancel';
exports.ORDER_CANCEL = ORDER_CANCEL;
var roles = {
  MASTER: 'master',
  USER: 'user',
  PUBLISHER: 'publisher',
  ADMIN: 'admin'
};
exports.roles = roles;