"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.mongo = void 0;

var Realm = _interopRequireWildcard(require("realm-web"));

var APP_ID = process.env.REACT_APP_ID;
console.log("TCL: APP_ID", APP_ID);
var app = new Realm.App(APP_ID);
var mongo = app.services.mongodb(process.env.REACT_APP_SV).db(process.env.REACT_APP_DB);
exports.mongo = mongo;
var _default = app;
exports["default"] = _default;