"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginActions = exports.CHANGEPASSWORD = exports.AUTH_ORDER = exports.REFRESH = exports.ACTIVATE = exports.LOGOUT = exports.RECOVERYCONFIRM = exports.RECOVERY = exports.SIGNUP = exports.ANONYMOUS = exports.CONFIRM = exports.LOGIN = void 0;

var _toolkit = require("@reduxjs/toolkit");

var LOGIN = 'auth/LOGIN';
exports.LOGIN = LOGIN;
var CONFIRM = 'auth/CONFIRM';
exports.CONFIRM = CONFIRM;
var ANONYMOUS = 'auth/ANONYMOUS';
exports.ANONYMOUS = ANONYMOUS;
var SIGNUP = 'auth/SIGNUP';
exports.SIGNUP = SIGNUP;
var RECOVERY = 'auth/RECOVERY';
exports.RECOVERY = RECOVERY;
var RECOVERYCONFIRM = 'auth/RECOVERY-CONFIRM';
exports.RECOVERYCONFIRM = RECOVERYCONFIRM;
var LOGOUT = 'auth/LOGOUT';
exports.LOGOUT = LOGOUT;
var ACTIVATE = 'auth/ACTIVATE';
exports.ACTIVATE = ACTIVATE;
var REFRESH = 'auth/REFRESH';
exports.REFRESH = REFRESH;
var AUTH_ORDER = 'auth/ORDER';
exports.AUTH_ORDER = AUTH_ORDER;
var CHANGEPASSWORD = 'auth/CHANGE-PASSWORD';
exports.CHANGEPASSWORD = CHANGEPASSWORD;
var loginActions = {
  login: (0, _toolkit.createAction)(LOGIN),
  confirm: (0, _toolkit.createAction)(CONFIRM),
  loginAnonymous: (0, _toolkit.createAction)(ANONYMOUS),
  logout: (0, _toolkit.createAction)(LOGOUT),
  recoveryPassword: (0, _toolkit.createAction)(RECOVERY),
  recoveryPasswordConfirm: (0, _toolkit.createAction)(RECOVERYCONFIRM),
  changePassword: (0, _toolkit.createAction)(CHANGEPASSWORD),
  signup: (0, _toolkit.createAction)(SIGNUP),
  activate: (0, _toolkit.createAction)(ACTIVATE)
};
exports.loginActions = loginActions;