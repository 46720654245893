import { ACCOUNT, LOGOUT } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'
import { USERS_MANAGEMENT, PUBLISHERS } from '../App/routes'

export const getMenuConfig = (role = 'none') => [
  {
    title: 'Utenti',
    linkTo: `/${USERS_MANAGEMENT}`,
  },
  {
    title: 'Publishers',
    linkTo: `/${PUBLISHERS}`,
  },
  {
    title: 'Account',
    linkTo: ACCOUNT,
  },
  {
    title: 'Logout',
    linkTo: LOGOUT
  }
]
