import { all } from 'redux-saga/effects'
import auth from '@stackhouse/rassegnastampa-core/lib/containers/Auth/saga'
import stepperState from '@stackhouse/rassegnastampa-core/lib/containers/StepperState/saga'
import App from '../containers/App/saga'
import Users from '../containers/Users/saga'
import Publishers from '../containers/Publishers/saga'
import Invitations from '../containers/Invitations/saga'

//const context = require.context('../', true, /containers\/[\s\S]*?\/saga.js$/)
//console.log('context', context)
//const sagas = context.keys().map(filename => context(filename).default)

export default function* root() {
  yield all(
    [
      auth(),
      stepperState(),
      App(),
      Users(),
      Publishers(),
      Invitations()
      //...sagas.map(saga => saga())
    ]
  )
}