"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LogoStyled = exports.MenuLinkExpand = exports.MenuWrapper = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background: white;\n  padding: 10px 20px;\n  height: 60px;\n  margin: 20px;\n  border-radius: 10px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  img{\n    object-fit: contain;\n    width: 100%;\n    height: 100%;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  color: ", ";\n  margin: 30px 0;\n  padding-left: 24px;\n  text-decoration: underline;\n  cursor: pointer;\n  opacity: 0.8;\n  &:hover {\n    opacity: 1;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n\n  .ant-menu {\n    background: none;\n    border: none;\n\n    .ant-menu-submenu-title,\n    .ant-menu-item,\n    .ant-menu-item a,\n    .ant-checkbox-wrapper {\n      color: ", ";\n    }\n\n    .ant-menu-submenu-title,\n    .ant-menu-item,\n    .ant-menu-item a {\n      transition: opacity .15s;\n      :hover {\n        opacity: 1 !important;\n      }\n    }\n\n    .menu-item-inactive {\n      .ant-menu-submenu-title {\n        opacity: 0.8;\n      }\n    }\n\n    .ant-menu-item,\n    .ant-menu-submenu-title {\n      :active {\n        background: rgba(240, 240, 240, .1);\n      }\n    }\n\n    .ant-menu-item-selected {\n      background-color: transparent;\n      ::after {\n        display: none;\n      }\n    }\n\n    .ant-checkbox-wrapper {\n      pointer-events: none;\n    }\n\n    .ant-radio-wrapper {\n      pointer-events: none;\n    }\n\n    .menu-item-active,\n    .menu-item-active .ant-menu-submenu-title {\n      opacity: 1;\n    }\n\n    .menu-item-inactive {\n      ul {\n        cursor: not-allowed;\n        li {\n          pointer-events: none;\n        }\n      }\n    }\n  }\n\n  .ant-menu-submenu-title,\n  .ant-menu-root > .ant-menu-item {\n    font-weight: bold;\n    font-size: 18px;\n  }\n\n  .ant-menu-root {\n    > li {\n      margin: 30px 0;\n    }\n\n    .ant-menu-submenu-arrow {\n      ::before, ::after {\n        background: ", ";\n      }\n    }\n  }\n\n  .ant-menu-sub {\n    .ant-menu-item {\n      padding-left: 24px !important;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MenuWrapper = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var theme = _ref.theme;
  return theme.colors.white;
}, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.white;
});

exports.MenuWrapper = MenuWrapper;

var MenuLinkExpand = _styledComponents["default"].div(_templateObject2(), function (_ref3) {
  var theme = _ref3.theme;
  return theme.colors.white;
});

exports.MenuLinkExpand = MenuLinkExpand;

var LogoStyled = _styledComponents["default"].div(_templateObject3());

exports.LogoStyled = LogoStyled;