export const HOME = '/'
export const DASHBOARD = '/'

// Dashboard child routes
export const PUBLISHERS = 'publishers'
export const PUBLISHER_EDIT = 'publishers/:id'
export const PUBLISHER_CREATE = 'publishers/create'
export const USERS_MANAGEMENT = 'users'
export const USER_DETAIL = 'users/:userId'
export const USER_CREATE = 'users/create'

export const routesForBreadcrumbs = {
  dashboard: {
    name: 'Rassegna Stampa'
  },
  publishers: {
    name: 'Gestione Publishers'
  },
  users: {
    name: 'Gestione Utenti'
  },
  userDetail: {
    name: 'Modifica'
  },
}