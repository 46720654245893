import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Menu from '@stackhouse/rassegnastampa-core/lib/components/Menu'
import { getUserRole } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'

import { getMenuConfig } from './config'
import { actions } from './reducer'

const actionsMap = {
  keywords: actions.toggleKeyword,
  publishers: actions.togglePublisher
}

// TODO: spostare in dashboard container?
const SidebarMenu = ({ location }) => {
  const dispatch = useDispatch()

  const userRole = useSelector(getUserRole)

  const menuItems = useMemo(
    () => getMenuConfig(userRole),
    [userRole]
  )

  const onItemClick = ({ keyPath }) => {
    const [key, rootKey] = keyPath
    const action = actionsMap[rootKey]
    if (action) dispatch(action(key))
  }

  return (
    <Menu
      location={location}
      items={menuItems}
      // selectedKeys={[...selectedKeywords, ...selectedPublishers]}
      onItemClick={onItemClick}
    />
  )
}

export default SidebarMenu
