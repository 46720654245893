import React, { lazy, Suspense } from 'react'
import { useLocation } from '@reach/router'
import { Router } from '@reach/router'
import Page from '@stackhouse/rassegnastampa-core/lib/components/Page'
import Loading from '@stackhouse/rassegnastampa-core/lib/components/Loading'

import Menu from '../../containers/SidebarMenu'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { routesForBreadcrumbs } from '../../containers/App/routes'
import { ACCOUNT, CHANGEPASSWORD } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'

const NotFound = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/NotFound'))
const Home = lazy(() => import('../Home'))

const PublisherList = lazy(() => import('../Publishers/PublisherList'))
const EditPublisher = lazy(() => import('../Publishers/EditPublisher'))

const UsersList = lazy(() => import('../Users/UsersList'))
const EditUser = lazy(() => import('../Users/EditUser'))

const Account = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/Account'))
const ChangePassword = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/ChangePassword'))

export default () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: 'INIT_APP', payload: { routes: routesForBreadcrumbs } })
  }, [dispatch])

  return (
    <Page sidebarContent={<Menu location={location} />}>
      <Suspense fallback={<Loading />}>
        <Router>
          <Home path='/' />
          <PublisherList path='publishers' />
          <EditPublisher path='publishers/:id' />
          <UsersList path='users' />
          <EditUser path='users/:userId' />
          <NotFound default />
          <Account path={ACCOUNT} />
          <ChangePassword path={CHANGEPASSWORD} />
        </Router>
      </Suspense>
    </Page>
  )
}
