"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RightImage = exports.RegisterAccount = exports.Header = exports.Content = exports.LoginContainer = exports.StartPageContainer = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _theme = require("../../utils/theme");

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    display: none;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background: url(assets/newspapers.jpg);\n  min-height: 100vh;\n  width: 40%;\n  background-size: cover;\n  background-position: 50% 50%;\n  border-top-left-radius: 60px;\n\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-top: 40px;\n  ", "\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    left: 30px;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: absolute;\n  top: 32px;\n  left: 120px;\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    .cta-box {\n      width: 70%;\n      h1 {\n        display: block;\n      }\n      span {\n        margin-bottom: 120px;\n      }\n    }\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    padding: 120px;\n    .cta-box {\n      padding-top: 0;\n      width: 80%;\n      h1 {\n        display: inline-block;\n      }\n    }\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  display: flex;\n  flex: 1;\n  flex-direction: column;\n  justify-content: center;\n  min-height: inherit;\n  padding: 120px 30px;\n  .cta-box {\n    padding-top: 20px;\n  }\n\n  ", "\n\n  ", "\n  h2 {\n    margin-bottom: 40px;\n  }\n  .ant-form {\n    max-width: 512px;\n    width: 100%;\n    margin: 0 auto;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  min-height: 100vh;\n  display: flex;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  display: flex;\n  min-height: 100vh;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StartPageContainer = _styledComponents["default"].div(_templateObject());

exports.StartPageContainer = StartPageContainer;

var LoginContainer = _styledComponents["default"].div(_templateObject2());

exports.LoginContainer = LoginContainer;

var Content = _styledComponents["default"].div(_templateObject3(), _theme.media.minWidth('md')(_templateObject4()), _theme.media.minWidth('lg')(_templateObject5()));

exports.Content = Content;

var Header = _styledComponents["default"].div(_templateObject6(), _theme.media.maxWidth('md')(_templateObject7()));

exports.Header = Header;

var RegisterAccount = _styledComponents["default"].div(_templateObject8(), function (_ref) {
  var isLoading = _ref.isLoading;
  return isLoading && "\n    opacity: 0.6;\n    pointer-events: none;  \n  ";
});

exports.RegisterAccount = RegisterAccount;

var RightImage = _styledComponents["default"].div(_templateObject9(), _theme.media.maxWidth('sm')(_templateObject10()));

exports.RightImage = RightImage;